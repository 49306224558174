import * as http from 'http'
import { useEffect } from 'react'

/**
 * @typedef MetadataState
 * @property {boolean} isLive
 * @property {string} title
 */

/** @type {MetadataState} */
export const metadataState = {
  isLive: false,
  title: '',
}

const host = window.location.host

/**
 * @typedef {(prevState: MetadataState, newState: MetadataState) => void}  Hook
 */

/** @type {Hook[]} */
let hooks = []
const subscribe = (h) => hooks.push(h)
const unsubscribe = (h) => {
  hooks = hooks.filter(hook => hook !== h)
}

/** @param {Hook} hook */
export const useHook = (hook) => useEffect(() => {
  subscribe(hook)
  return () => unsubscribe(hook)
}, [])

let connectionCount = 0

export const connect = async () => {
  try {
    if (connectionCount >= 30) return
    const ws = await new WebSocket(`wss://${host}/connect`)
    connectionCount++
    ws.onclose = connect
    ws.onmessage = (message) => {
      /** @type {MetadataState} */
      const data = JSON.parse(message.data)
      const oldState = metadataState
      metadataState.isLive = data.isLive
      metadataState.title = data.title
      const newState = metadataState
      hooks.forEach(hook => hook(oldState, newState))
    }
  } catch (e) {
    return
  }
}

