import React from "react";

/**
 * @param {{ show: boolean }} props
 */
const Calendar = (props) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const uriTimeZone = window.encodeURIComponent(localTimeZone)
  const src = `https://calendar.google.com/calendar/embed?src=${encodeURIComponent('deadbeatradio01@gmail.com')}&ctz=${uriTimeZone}&wkst=1&mode=WEEK&showTitle=0&showPrint=0&bgcolor=%23000000&showDate=0&showCalendars=0&color=%23616161`

  return (
    <div style={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
    <div style={{ display: props.show ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', maxWidth: 1000}}>
      <h1 style={{marginBottom: "20px"}}>Show Schedule</h1>
      <iframe title="deadbeat-schedule" src={src} width="100%" height="500" frameBorder="0" scrolling="no"></iframe>
    </div>
    </div>
  )
}

export default Calendar;
