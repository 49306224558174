import React from "react";
import "./Nav.css";
import {Link} from "react-router-dom";

const Nav = () => {
  return (
    <div className="nav-container">
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/shows">Shows</Link>
      <Link to="/schedule">Schedule</Link>
    </div>
  )
}

export default Nav;
