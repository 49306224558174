import React from 'react'
import './Home.css'

const Home = () => (
  <div className="home-container">
    <div className="center-container">
      <span className="centerfold-framing">welcome to deadbeat radio</span>
      <img className="centerfold" src="./globe1.gif" alt=""/>
      <span className="centerfold-framing"> on the worldwide web </span>
    </div>
  </div>
)

export default Home
