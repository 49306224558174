import React, { useContext } from 'react'
import { DBContext } from '../utilities/db'
import { toLocalTime } from '../utilities/dates'
import './Shows.css'

/** @typedef {'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday'} DayOfWeek */

/** @type {DayOfWeek[]} */
const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

/**
 * @param {ReturnType<toLocalTime>} param0
 */
const timeToString = ({hour, minute}) => {
  const mS = minute.toString().length === 1 ? `0${minute}` : minute.toString()

  const h12 = hour % 12 === 0 ? 12 : hour % 12

  const ampm = hour < 12 ? 'am' : 'pm'

  return `${h12}:${mS} ${ampm}`
}

/** @typedef {import('../utilities/api').APIPost} APIPost */
/** @typedef {import('../utilities/db').DBEntry} DBEntry */

/**
 * callback to sort array by prop
 * @type {(prop: keyof APIPost) => (a: DBEntry, b: DBEntry) => number}
 */
const by = (prop) => (a, b) => a.post[prop].rendered ? a.post[prop].rendered.localeCompare(b.post[prop].rendered) : a.post[prop].localeCompare(b.post[prop])

/**
 * @param {{day: number, hour: number, minute: number}} startTime
 * @param {{day: number, hour: number, minute: number}} endTime
 * @param {'1' | [] | '0'} biweekly
 */
const fullTimeString = (startTime, endTime, biweekly) => {
  /** @type {boolean} */
  const isBiweekly = biweekly === '1'
  return `${isBiweekly ? 'Every other ' : ''}${daysOfWeek[startTime.day]}${isBiweekly ? '' : 's'} ${timeToString(startTime)}${endTime ? ` - ${timeToString(endTime)}` : ''}`
}

const Shows = (props) => {
  const db = useContext(DBContext)
  return (
    <table className="shows-container">
      <thead className="shows-head">
        <h1>Shows</h1>
      </thead>
      <tbody>
        {db
          .filter(v => v.categories.includes('Shows'))
          .sort(by('title'))
          .map((show, i) => {
            const startTime = toLocalTime(show.post.day[0], show.post.start_time)
            const endTime = show.post.end_time ? toLocalTime(show.post.day[0], show.post.end_time) : undefined
            return (
              <tr key={i} className={`show ${i === 0 ? 'show-first' : ''}`}>
                <td className="show-avatar-box">
                {(show.post.featured_media ?? null) !== null && <img alt={show.post.title.rendered} src={show.media.source_url} className="show-avatar"/>}
                </td>
                <td className="show-information">
                  <h2 className="show-title">
                    {show.post.title.rendered}
                  </h2>
                  {Boolean(show.post.day) && <span><em>{fullTimeString(startTime, endTime, show.post.biweekly)}</em></span>}
                  {Boolean(show.post.hosts) && <span><em>hosted by: {show.post.hosts}</em></span>}
                  <div className="show-content">{show.post.content.rendered}</div>
                  {Boolean(show.post.episodes) && <a target="_blank" href={show.post.episodes}>Past episodes</a>}
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

export default Shows;
