import { getPosts, getMedia, getCategories } from './api'
import { useReducer, createContext, useEffect } from 'react'
import parse from 'html-react-parser'

/**
 * @typedef DBEntry
 * @property {import('./api').APIPost} post
 * @property {import('./api').APIMedia} media
 * @property {import('./api').APICategory['name'][]} categories
 */

/**
 * @typedef {Array<DBEntry>} DB
 */

/** @type {React.Reducer<DB, { type: 'SET_DB', payload: DB}>} */
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DB': {
      return action.payload
    }
    default: return state
  }
}

/** @type {DB} */
const initialState = []

/**
 * fetches all the data we need from wordpress
 */
const constructDB = async () => {
  const [posts, media, categories] = await Promise.all([getPosts(), getMedia(), getCategories()])
  const combedMedia = media.map(m => {
    const source_url = m.source_url.replace(/^http:/, 'https:')
    return {
      ...m,
      source_url,
    }
  })
  return posts.map(p => ({
    post: {
      ...p,
      content: {
        ...p.content,
        rendered: parse(p.content.rendered),
      },
    },
    media: combedMedia.find(m => m.id === p.featured_media),
    categories: p.categories.map(id => categories.find(c => c.id === id).name),
  }))
}

/**
 * Use this context any time we need to access the local database
 */
export const DBContext = createContext(initialState)

/**
 * Wrap our entire app in this component once to provide access to the DBContext
 *
 * @param {{ children: React.ReactNode }} param0
 */
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    constructDB()
      .then(db => {
        dispatch({
          type: 'SET_DB',
          payload: db,
        })
      })
  }, [])

  return (
    <DBContext.Provider value={state}>
      {children}
    </DBContext.Provider>
  )
}


export default Store
