const postsUrl = `/api/posts`

const mediaUrl = `/api/media`

const categoriesUrl = `/api/categories`

/**
 * @typedef APIPost
 * @property {number} id
 * @property {object} content
 * @property {boolean} content.protected
 * @property {string} content.rendered
 * @property {number} featured_media ID of related media
 * @property {object} title
 * @property {string} title.rendered
 * @property {number[]} categories
 * @property {number[]} [day] day of week at start of show (in CST). 0=sunday, 1=monday, ..., 6=saturday
 * @property {string} [start_time] end time of show (24-hr hh:mm:ss in CST)
 * @property {string} [end_time] end time of show (24-hr hh:mm:ss in CST)
 * @property {string} [hosts] the hosts of the show
 * @property {string} [episodes] episodes url
 * @property {"1" | [] | "0"} biweekly
 */

/**
 * @typedef APIMedia
 * @property {string} source_url
 * @property {number} id
 */

/**
 * @typedef APICategory
 * @property {number} id
 * @property {string} name
 */

/**
 * @returns {Promise<Array<APIPost>>}
 */
export const getPosts = () =>
  fetch(postsUrl)
    .then(res => res.json())

/**
 * @returns {Promise<Array<APIMedia>>}
 */
export const getMedia = () =>
  fetch(mediaUrl)
    .then(res => res.json())

/**
 * @returns {Promise<Array<APICategory>>}
 */
export const getCategories = () =>
  fetch(categoriesUrl)
    .then(res => res.json())

