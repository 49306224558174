import React, {useContext} from "react";
import { DBContext } from '../utilities/db';
import "./About.css";

const About = () => {
  const db = useContext(DBContext);
  return (
    <div className="about-container">
      <h1>About Us</h1>
      {db.filter(v => v.categories.includes('About')).map((show, i) => <div className="about-content" key={i}>{show.post.content.rendered}</div>
        )}
    </div>
  )
}

export default About;
