import { metadataState, useHook } from '../utilities/websocket'
import React, { useReducer, useEffect, useContext } from 'react';
import { DBContext } from '../utilities/db'


/** @type {import('../utilities/websocket').MetadataState} */
const initialMetadataState = metadataState
/** @type {(prevState: import('../utilities/websocket').MetadataState, newState:  import('../utilities/websocket').MetadataState) => import('../utilities/websocket').MetadataState} */
const metadataReducer = (prevState, newState) => {
  return {
    ...prevState,
    ...newState,
  }
}

/** @param {import('../utilities/websocket').MetadataState} state */
const displayTitle = (state) => {
  if (state.isLive) {
    // make sure it's part of the show
    if (!state.title) {
      return ''
    }
    return state.title.split(' - ')[0]
  }
  // either rerun or fallback
  if (state.title === 'fallback') {
    return ''
  }
  if (!state.title) {
    return ''
  }
  return state.title.split(' - ')[0]
}

/**
 * @param {import('../utilities/websocket').MetadataState} metadata
 */
const statusContent = (metadata) => {
  if (metadata.isLive) {
    return 'LIVE'
  }
  if (metadata.title.toLowerCase() === 'fallback') {
    return ''
  }
  return '(rerun)'
}

/**
 * @param {import('../utilities/websocket').MetadataState} metadata
 */
const statusStyle = (metadata) => {
  return {
    ...(Boolean(metadata.isLive) && { backgroundColor: 'red', paddingLeft: '4px', paddingRight: '4px', }),
  }
}

const Metadata = () => {
  const [metadata, metadataDispatch] = useReducer(metadataReducer, metadataState)
  useHook(metadataDispatch)
  const db = useContext(DBContext)

  /**
   * @param {import('../utilities/websocket').MetadataState} metadata
   */
  const titleContent = (metadata) => {
    const content = displayTitle(metadata)
    const show = db.find((entry) => {
      if (!entry.categories.includes('Shows')) {
        return false
      }
      let acceptableTitles = [entry.post.title.rendered.toLowerCase()]
      if (entry.post.title.rendered === 'Just Passin Thru') {
        acceptableTitles.push("just passin' thru")
      }
      return acceptableTitles.includes(content.toLowerCase())
    })
    if (!show) {
      return ''
    } else {
      return show.post.title.rendered
    }
  }

  /**
   * @param {import('../utilities/websocket').MetadataState} metadata
   */
  const displayMetadata = (metadata) => {
    if (metadata.isLive) {
      return true
    }
    if (metadata.title === 'fallback' || titleContent(metadata) === '') {
      return false
    }
    return true
  }

  return (
    <>
      {displayMetadata(metadata) && (
        <div className="metadata" >
          <div className="audio-row">
            <div className="audio-descriptor" style={statusStyle(metadata)}>
              {statusContent(metadata)}
            </div>
            <div className="audio-descriptor">
              {titleContent(metadata)}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Metadata
