/** @typedef {import('./db').DBEntry} DBEntry */

/** @typedef {'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday'} DayOfWeek */

/** @type {DayOfWeek[]} */
const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

/** @type {Intl.DateTimeFormatOptions} */
const centralTimeOptions = {
  timeZone: 'America/Chicago',
  hour12: false,
  weekday: 'long',
  hour: 'numeric',
  minute: 'numeric',
}

/**
 * @param {Intl.DateTimeFormatPart[]} parts
 * @param {Intl.DateTimeFormatPartTypes} prop
 */
const partsToValue = (parts, prop) => {
  const entry = parts.find(part => part.type === prop)
  return entry ? entry.value : undefined
}

/**
 * given in minutes. positive value means local is ahead of chicago
 */
const getDifferenceFromChicago = () => {
  const now = new Date()
  const local = {
    day: now.getDay(),
    hour: now.getHours(),
    minute: now.getMinutes(),
  }

  const chicagoTime = Intl.DateTimeFormat('en-us', centralTimeOptions).formatToParts(now)
  const chicago = {
    day: daysOfWeek.indexOf(partsToValue(chicagoTime, 'weekday')),
    hour: Number(partsToValue(chicagoTime, 'hour')),
    minute: Number(partsToValue(chicagoTime, 'minute')),
  }

  // positive value means local is ahead of chicago
  let dayDifference = local.day - chicago.day
  if (dayDifference === 6) dayDifference = -1
  if (dayDifference === -6) dayDifference = 1

  // positive value means local is ahead of chicago
  const hourDifference = dayDifference * 24 + (local.hour - chicago.hour)
  // positive value means local is ahead of chicago
  const minuteDifference = hourDifference * 60 + (local.minute - chicago.minute)

  return minuteDifference
}

/** Given in minuetes. Positive value means local is ahead of chicago */
const differenceFromChicago = getDifferenceFromChicago()

const minutesPerWeek = 60 * 24 * 7
const minutesPerDay = 60 * 24
const minutesPerHour = 60

/**
 * @param {DBEntry['post']['day'][0]} day
 * @param {DBEntry['post']['start_time']} time
 */
export const toLocalTime = (day, time) => {
  const [h, m] = time.split(':').map(Number)
  const chicagoMinute = (day * minutesPerDay) + (h * minutesPerHour) + m

  const localMinute = (chicagoMinute + differenceFromChicago + minutesPerWeek) % minutesPerWeek

  const localDay = Math.floor(localMinute/minutesPerDay)
  const localHour = Math.floor((localMinute % minutesPerDay) / 60)
  const localMin = localMinute % 60
  return {
    day: localDay,
    hour: localHour,
    minute: localMin,
  }
}

/**
 * @param {DBEntry['post']} post
 */
