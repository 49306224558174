import React, { useReducer, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import Nav from "./components/Nav";
import About from "./components/About";
import Home from "./components/Home"
import Shows from "./components/Shows"
import Calendar from "./components/Calendar"
import Metadata from './components/Metadata.jsx'
import Store from './utilities/db';
import { metadataState, useHook } from './utilities/websocket'

/**
 * @typedef ScheduleTriggerEvent
 * @property {boolean} show
 */

const initialScheduleState = {
  show: false
}

/**
 * @param {ScheduleTriggerEvent} prevState
 * @param {ScheduleTriggerEvent} event
 */
const scheduleReducer = (prevState, event) => {
  return {
    ...prevState,
    ...event,
  }
}

function App() {
  const [state, dispatch] = useReducer(scheduleReducer, initialScheduleState)

  const ScheduleTrigger = () => {
    useEffect(() => {
      if (!state.show) {
        dispatch({ show: true })
      }
      return () => {
        if (state.show) {
          dispatch({ show: false })
        }
      }
    }, [])
    return <></>
  }

  const arr = Array.from(new Array(9))
  const rand = arr.map(() => Math.floor(Math.random() * 10).toString()).join('')

  return (
    <div className="App">
      <Store>
      <Router>
        <div className="audio-row" >
          <audio controls={true} preload="none">
            <source src={`/stream?nocache=${rand}`} type="audio/mpeg" />
          </audio>
          <Metadata />
        </div>
        <Nav />
        <Switch>
          <Route exact path="/about" component={About}/>
          <Route exact path='/shows' component={Shows}/>
          <Route exact path="/schedule" component={ScheduleTrigger}/>
          <Route exact path="/" component={Home} />
          <Route exact path='/shows' component={Shows}/>
        </Switch>
        <Calendar show={state.show} />
      </Router>
      </Store>
    </div>
  );
}

export default App;
